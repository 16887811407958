import self from "../img/me.png"
import mock1 from "../img/frame1.png"
import mock2 from "../img/frame2.png"
export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export let singlePage = false;


export const info = {
    firstName: "Umidbek",
    lastName: "Jumanazarov",
    initials: "xApi", // the example uses first and last, but feel free to use three or more if you like.
    position: "Full Stack и Flutter Разработчик",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '🧠',
            text: 'Интерактивы'
        },
        {
            emoji: '🌎',
            text: 'Узбекистан, Хива'
        },
        {
            emoji: "💼",
            text: "Разработчик Программного Обеспечения Узбекистан"
        },
        {
            emoji: "📧",
            text: "xivaapi@gmail.com"
        }
    ],
    socials: [
        {
            link: "https://t.me/xapi_uz",
            icon: 'fa fa-telegram',
            label: 'telegram'
        },
        {
            link: "https://www.instagram.com/xapi.uz",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/UmidbekJumanazarov",
            icon: "fa fa-github",
            label: 'github'
        },


    ],
    bio: "Ас-саляму алейкум, меня зовут.Жуманазаров Умидбек Я Разработчик {Web, Mobile, Script} создаю сайты и программы для малого и среднего бизнеса и делаю свой проеты ",
    skills:
        {
            proficientWith: ['javascript', 'react', 'dart', 'git', 'bootstrap', 'html5', 'css3', 'figma',],
            exposedTo: ['nodejs', 'python', 'php', 'flutter']
        }
    ,
    hobbies: [
        {
            label: 'Чтение',
            emoji: '📖'
        },
        {
           label:'Алгоритм',
            emoji:'💾'

        },
        {
            label: 'Разработка Игр',
            emoji: '🎮'
        },
        {
            label: 'Пиксельная графика',
            emoji: '🎨'
        },

    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Ислам в Твери",
            live: "https://play.google.com/store/apps/details?id=com.xapi.islam_tver", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            image: mock1
        },
        {
            title: "Sajda UZ",
            live: "https://play.google.com/store/apps/details?id=com.xapi.sajda_uz",
            image: mock2
        }
    ]
}